@import "../../variables.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap");

$bubble-radius: 250px;
$poster-text-color-primary: #f3f3f3;
$description-text-primary: #b9c7ff;
$scrollbar-color: #888;
$scrollbar-hover-color: #555;

$serviceCard-stroke: 2px;
$serviceCard-border: $serviceCard-stroke solid darken($blue, 10);
$serviceCard-transition_duration: 100ms;
$serviceCard-pipes: 4;

$breakpoint-mobile: 768px;
$breakpoint-tablet: 1200px;

@function border-pipeline($serviceCard-transition_duration, $pipe) {
    @return $serviceCard-transition_duration * $pipe;
}

@mixin border-animation($dimension, $easing, $pipe) {
    transition: $serviceCard-transition_duration $dimension $easing border-pipeline($serviceCard-transition_duration, $pipe);
    -web-kit-transition: $serviceCard-transition_duration $dimension $easing border-pipeline($serviceCard-transition_duration, $pipe);
}

body {
    background-color: $blue;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: $blue; 
}

::-webkit-scrollbar-thumb { 
    background: $scrollbar-color; 
}

::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-hover-color; 
}

.poster {
    width: 100%;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .anim-text {
        animation: anim-text 1000ms cubic-bezier(0, 0.45, 0.15, 1);
        @media screen and (max-width: $breakpoint-tablet) {
            animation: none;
        }
    }

    span.bubble {
        display: flex;
        position: relative;
        transform: scale(0);
        border-radius: 50%;

        &:nth-child(1) {
            height: 200px;
            width: 200px;
            top: calc(50% - 300px);
            background-color: $yellow;
            box-shadow: inset 25px 35px 50px #faf7db;
            @media screen and (max-width: $breakpoint-tablet) {
                height: 130px;
                width: 130px;
                top: calc(50% - 100px);
                left: 50px;
                top: -15px;
            }
            & > span:first-of-type {
                margin: 0 auto;
                margin-top: 70px;
                font-size: 24px;
                font-weight: 600;
                color: $dark-blue;
                font-family: "Source Code Pro", monospace;
                @media screen and (max-width: $breakpoint-tablet) {
                    margin-top: 55px;
                    font-size: 15px;
                }
            }
        }
        &:nth-child(2) {
            height: 300px;
            width: 300px;
            margin: -200px 0px 0px 100px;
            background-image: url("../../resources/cv-photo.webp");
            background-size: cover;
            @media screen and (max-width: $breakpoint-tablet) {
                height: 170px;
                width: 170px;
                left: 110px;
                margin-top: -160px;
            }
        }

        @for $i from 1 through 2 {
            &:nth-child(#{$i}) {
                animation: bubble-grow 1s ease-in-out (3 - $i) * (0.5s) normal forwards;
            }
        }
    }

    .texting {
        margin-top: 0px;
        h1 {
            color: $poster-text-color-primary;
            font-size: 60px;
            padding-left: 50px;
            margin: 0px;
            line-height: 80px;
            letter-spacing: 1px;
            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 50px;
                line-height: 65px;
                text-align: center;
                padding-left: 0px;
            }

            span.del-text {
                position: relative;
                &::after {
                    content: " ";
                    position: absolute;
                    top: calc(50% - 2px);
                    left: 0;
                    width: 0%;
                    height: 4px;
                    background: $red;
                    animation-name: strike;
                    animation-duration: 0.5s;
                    animation-timing-function: linear;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-delay: 1s;
                }
                color: $red;

                span {
                    color: $poster-text-color-primary;
                }
            }
        }
        span.smile-text {
            position: relative;
            top: -50px;
            left: 250px;
            color: white;
            font-size: 80px;
            transform: rotate(20deg);
            display: inline-flex;
            opacity: 0;
            font-family: "Gloria Hallelujah", cursive;
            font-weight: 400;
            animation: smile-text-show 1s ease-in-out 1.5s normal forwards;
            @media screen and (max-width: $breakpoint-tablet) {
                left: calc(50% + 100px);
                font-size: 55px;
                top: -20px;
            }
            
            @media screen and (max-width: $breakpoint-mobile) {
                left: calc(50% + 20px);   
            }
        }
    }

    .bubbles-container {
        @media screen and (max-width: $breakpoint-tablet) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            height: 190px;
            
            & span.bubble {
                margin: 0;
                position: inherit;
            }
        }
    }

    .tag {
        margin-top: 0px;
        padding: 50px;
        display: flex;
        align-items: center;
        color: $red;
        font-weight: 600;
        letter-spacing: 1px;

        @media screen and (max-width: $breakpoint-tablet) {
            padding: 50px 20px;
            text-align: center;
            justify-content: center;
        }

        div.line {
            width: 30px;
            display: flex;
            align-items: center;
            height: 1px;
            background-color: $poster-text-color-primary;
            margin: 0px 30px;

            @media screen and (max-width: $breakpoint-tablet) {
                margin: 0px 10px;
            }
        }
    }

    p.description {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 140%;
        color: $description-text-primary;
        margin-top: 40px;
        padding-right: 50px;

        @media screen and (max-width: $breakpoint-tablet) {    
            margin: 0px 50px;
            text-align: center;
            padding: 0;
        }
    }

    .blob {
        z-index: 99999;
        margin-left: -50px;
        position: relative;
        svg {
            animation: scale-background 2000ms cubic-bezier(0, 0.45, 0.15, 1);
            @media screen and (max-width: $breakpoint-tablet) {
                animation: none;
            }
        }
        img {
            width: 60px;
            height: 60px;
            opacity: 0;
            position: absolute;
            animation: 0px;
            top: 65px;
            z-index: 99999;
            left: 125px;
            animation: bubble-grow 0.5s ease-in-out 1s normal forwards;
        }

        @media screen and (max-width: $breakpoint-tablet) {
            display: flex;
            justify-content: center;    
            margin-left: 0px; 
            img {
                left: auto;
            }       
        }
    }
}

.card-outline-effect {
    margin: 0px 20px 20px 20px;
    border: 2px solid $poster-text-color-primary;
    border-radius: 13px;
    border: $serviceCard-border;
    position: relative;
    cursor: pointer;
    transition: ($serviceCard-transition_duration * $serviceCard-pipes) + $serviceCard-transition_duration ease;
    &:before {
        position: absolute;
        width: 0;
        height: $serviceCard-stroke;
        content: "";
        background: #fff;
        top: -$serviceCard-stroke;
        left: -$serviceCard-stroke;
        @include border-animation(width, ease, 3);
    }
    &:after {
        position: absolute;
        width: $serviceCard-stroke;
        height: 0;
        content: "";
        background: #fff;
        top: -$serviceCard-stroke;
        right: -$serviceCard-stroke;
        @include border-animation(height, ease, 2);
    }
    &-inner:after {
        position: absolute;
        width: 0;
        height: $serviceCard-stroke;
        content: "";
        background: #fff;
        bottom: -$serviceCard-stroke;
        right: -$serviceCard-stroke;
        @include border-animation(width, ease, 1);
    }
    &-inner:before {
        position: absolute;
        width: $serviceCard-stroke;
        height: 0;
        content: "";
        background: #fff;
        bottom: -$serviceCard-stroke;
        left: -$serviceCard-stroke;
        @include border-animation(height, ease, 0);
    }
    &:hover {
        border-color: transparent;
        background: $dark-blue;
        border-radius: 0px;

        &:before {
            width: calc(100% + #{$serviceCard-stroke});
            @include border-animation(width, ease, 0);
        }
        &:after {
            height: calc(100% + #{$serviceCard-stroke});
            @include border-animation(height, ease, 1);
        }
        .card-inner:after {
            width: calc(100% + #{$serviceCard-stroke});
            @include border-animation(width, ease, 2);
        }
        .card-inner:before {
            height: calc(100% + #{$serviceCard-stroke});
            @include border-animation(height, ease, 3);
        }
    }
}

.services {
    margin: 35px 50px 0px 50px;
    .title {
        color: $blue;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        font-size: 75px;
        margin: 50px 0px 50px 0px;

        @media screen and (max-width: $breakpoint-tablet) {
            text-align: center;
            font-size: 50px;
        }
    }
    .card {
        .center-line {
            display: flex;
            justify-content: center;
        }
        .card-inner {
            padding: 40px 0px;
        }
        img {
            height: 70px;
        }
        p {
            font-size: 25px;
            letter-spacing: 4px;
            color: $poster-text-color-primary;
            margin-bottom: 0px;
        }
        @media screen and (max-width: $breakpoint-tablet) {
            min-width: 300px;
            width: 100%;
            margin: 10px 0px;
        }
    }
}

.about-me {
    .title {
        color: $blue;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        font-size: 75px;
        margin: 85px 50px 50px 50px;
        
        @media screen and (max-width: $breakpoint-tablet) {
            text-align: center;
            font-size: 45px;
            margin: 85px 20px 50px;
        }
    }
    .newspaper {
        @media screen and (max-width: $breakpoint-tablet) {
            margin: 0px 20px;
        }
    }
    .carousel-content {
        width: 100%;
        &.show-2 > * {
            width: calc(100% / 2);
        }
        &.show-3 > * {
            width: calc(100% / 3);
        }
        .card {
            a {
                text-decoration: none;
            }
            .news-image {
                display: flex;
                justify-content: center;
                position: relative;
                img.cover {
                    width: calc(100% - 60px);
                    height: 250px;
                    object-fit: cover;
                    border-radius: 10px 10px 0px 0px;
                    transition: border-radius .2s ease-in-out;
                    border-left: 2px solid $black;
                    border-right: 2px solid $black;
                    border-top: 2px solid $black;

                    @media screen and (max-width: $breakpoint-tablet) {
                        width: calc(100% - 10px);
                    }
                }
                .hoverOverlay {
                    opacity: 0;
                    margin-top: 2px;
                    transition: opacity .5s ease-out;
                    -moz-transition: opacity .5s ease-out;
                    -webkit-transition: opacity .5s ease-out;
                    -o-transition: opacity .5s ease-out;
                } 
                .openLinkNewTab {
                    position: absolute;
                    top: 20px;
                    right: 50px;
                    z-index: 1000;
                    transform: scale(0);
                    transition: transform .2s ease-in-out;

                    @media screen and (max-width: $breakpoint-tablet) {
                        right: 20px;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .news-title {
                padding: 30px 10px;
                margin: -10px 28px 0px;
                border-left: 2px solid $black;
                border-right: 2px solid $black;
                border-bottom: 2px solid $black;
                border-radius: 0px 0px 10px 10px;
                transition: 
                    border-color .1s ease-out,
                    border-radius .2s ease-out,
                    text-decoration .2s ease-in-out;
                text-align: center;
                font-size: 20px;
                color: $light-blue;
                letter-spacing: 1px;

                text-decoration: underline;
                text-decoration-color: transparent;
                -webkit-text-decoration-color: transparent;
                -moz-text-decoration-color: transparent;

                height: 50px;
                word-break: break-word;
                overflow-y: hidden;

                @media screen and (max-width: $breakpoint-tablet) {
                    margin: -10px 3px 0px;
                }
            }

            &:hover {
                @media screen and (min-width: $breakpoint-mobile) {
                    & .news-title {
                        cursor: pointer;
                        border-radius: 0px;
                        border-color: white;
                        background: $dark-blue;
                        text-decoration-color: $light-blue;
                        -webkit-text-decoration-color: $light-blue;
                        -moz-text-decoration-color: $light-blue;
                    }
                    & .news-image {
                        .cover {
                            border-radius: 0px;
                            border-color: white;
                        }
                        .hoverOverlay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1000;
                            background-color: $dark-blue;
                            float: left;
                            width: calc(100% - 60px);
                            height: 100%;
                            margin-left: 30px;
                            opacity: 0.8;

                            @media screen and (max-width: $breakpoint-tablet) {
                                width: calc(100% - 10px);
                                margin: 2px 5px;
                            }
                        }
                        .openLinkNewTab {
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

.my-experience {
    margin: 35px 50px 0px 50px;
    @media screen and (max-width: $breakpoint-tablet) {
        margin: 35px 10px 0px 10px;
    }
    .title {
        color: $blue;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        font-size: 75px;
        margin: 50px 0px 50px 0px;

        @media screen and (max-width: $breakpoint-tablet) {
            text-align: center;
            font-size: 50px;
        }
    }

    .my-experience-download-button {
        margin: 50px 0px 0px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        .downloadCV {
            text-decoration: none;
            padding: 20px 30px;
            color: $poster-text-color-primary;
            font-size: 25px;
            letter-spacing: 4px;
            display: flex;
            img {
                margin: -3px 20px 0px 0px;
            }
        }
    }

    .card-project {
        margin: 20px;
        position: relative;
        transition: all .2s ease-in-out; 
        &:hover {
            transform: scale(1.1);
            .project-video {
                display: block;

                @media screen and (max-width: $breakpoint-tablet) {
                    text-align: center;
                    display: none;
                }
            }
        }
        .browser-bg {
            width: 100%;
            position: relative;
        }
        .project-logo {
            max-width: 350px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            @media screen and (min-width: 600px) and (max-width: 775px) {
                max-width: 125px;
            }
        }
        .project-video {
            display: none;
            width: 90%;
            position: absolute;
            border-radius: 10px;
            left: 50%;
            top: 55%;
            transform: translateY(-50%) translateX(-50%);
        }
    }
}

.contact {
    margin: 35px 50px 0px 50px;
    .title {
        color: $blue;
        text-shadow: -1px -1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white, 1px 1px 0 $white;
        font-size: 75px;
        margin: 50px 0px 50px 0px;
        
        @media screen and (max-width: $breakpoint-tablet) {
            text-align: center;
            font-size: 60px;
        }
    }
    .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .card {
            margin: 10px;
            &.active {
                .card-content {
                    transition: 
                        border-color .5s ease-out,
                        background-color .5s ease-out
                        border-radius .5s ease-out
                        width 5s ease-in-out;
                    background-color: $dark-blue;
                    border-radius: 0px;
                    border-color: white;
                    .email-text, .phone-text {
                        display: inherit;
                        align-items: center;
                        padding-right: 10px;
                    }
                }
            }
            a {
                width: fit-content;
                height: 100%;
                img {
                    padding: 15px;
                    border-radius: 10px;
                    border: 2px solid $black;
                    transition: 
                        border-color .5s ease-out,
                        border-radius .5s ease-out,
                        background-color .5s ease-out;
                }
                &:hover img {
                    border-radius: 0px;
                    border-color: white;
                    background-color: $dark-blue;
                }
            }
            .card-content {
                display: flex;
                border: 2px solid $black;
                border-radius: 10px;
                transition: 
                    border-color .5s ease-out,
                    border-radius .5s ease-out,
                    background-color .5s ease-out;
                &:hover {
                    border-radius: 0px;
                    border-color: white;
                    background-color: $dark-blue;
                }
                img {
                    padding: 15px;
                }
                .email-text, .phone-text {
                    color: $light-blue;
                    display: none;
                    letter-spacing: 1px;
                }
            }
        }
        .card.email {
            img {
                padding: 18px 15px 19px;
            }
        }
    }
}

.footer {
    margin: 45px 0px 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: $light-blue;
    letter-spacing: 1px;
    .heart-emoji {
        color: red;
        margin: 0px 5px;
    }
}

@keyframes bubble-grow {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes strike {
    0% {
        width: 0;
    }
    100% {
        width: calc(100%);
    }
}

@keyframes smile-text-show {
    from {
        opacity: 0;
        transform: rotate(5deg) scale(0.8);
    }
    to {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
}

@keyframes anim-text {
    0% {
        transform: skew(0, 10deg) rotate(10deg) translate(200px, 1400px);
        opacity: 0;
    }
    60% {
        transform: skew(0, 10deg) rotate(10deg) translate(200px, 1400px);
        opacity: 0;
    }
    100% {
        transform: skew(0, 0) rotate(0) translate(0, 0);
        opacity: 1;
    }
}

@keyframes scale-background {
    0% {
        transform: scale(30) rotate(40deg);
    }
    20% {
        transform: scale(30) rotate(40deg);
    }
    50% {
        transform: scale(1) rotate(0);
    }
    100% {
        transform: scale(1) rotate(0);
    }
}

